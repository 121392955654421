<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="관련인허가 목록"
        isTitle
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        @linkClick="licensePop"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-license',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'licenseNo',
            field: 'licenseNo',
            label: '인허가 번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련 법규',
            align: 'center',
            sortable: false,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            label: '인허가종류',
            align: 'center',
            type: 'link',
            sortable: false,
          },
          {
            name: 'licenseStartDate',
            field: 'licenseStartDate',
            label: '인허가시작일',
            align: 'center',
            sortable: false,
          },
          {
            name: 'licenseEndDate',
            field: 'licenseEndDate',
            label: '인허가종료일',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.equipment.license.list.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {equipmentCd: this.param.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    licensePop(row) {
      this.popupOptions.target = () => import(`${'@/pages/sop/lim/licenseStatusDetail.vue'}`);
      this.popupOptions.title = '인허가 상세';
      this.popupOptions.param = {
        limLicenseId: row ? row.limLicenseId : '',
      };
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>